import React from "react";
import theme from "theme";
import { Theme, Text, Section, Icon, Strong, List, Box, Span, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaBalanceScale, FaWpforms, FaHandshake, FaCalculator, FaFileAlt, FaCoins, FaRegIdCard } from "react-icons/fa";
import { MdAssignmentTurnedIn, MdAccountBalance } from "react-icons/md";
import { TiCalculator } from "react-icons/ti";
import { IoMdPaper } from "react-icons/io";
import { GoLaw } from "react-icons/go";
import { GiBank } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"freelancers"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar />
		<Section
			padding="88px 0 88px 0"
			background="linear-gradient(0deg,--color-light 0%,--color-secondary 32.9%,rgba(0,0,0,0) 81%,rgba(255, 255, 255, 1) 100%),url(https://images.unsplash.com/photo-1528918387630-6b575e9fe5f9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center center/cover repeat scroll padding-box"
			quarkly-title="Hero-25"
			min-height="400px"
			align-items="flex-end"
			justify-items="center"
		>
			<Override
				slot="SectionContent"
				lg-justify-content="center"
				flex-direction="column"
				flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
				md-grid-template-columns="1fr"
				sm-margin="0px 0 0px 0"
				sm-width="100%"
				align-items="center"
				display="flex"
				justify-content="center"
				position="relative"
			/>
			<Text
				margin="0px auto 0px auto"
				font="normal 900 40px/1.2 --fontFamily-googleOpenSans"
				as="h1"
				color="#ffffff"
				text-align="center"
			>
				Bookkeeping for Freelancers (ZZP'ers)
				<br />
				in the Netherlands
			</Text>
		</Section>
		<Section background="--color-light" quarkly-title="Form">
			<Override slot="SectionContent" margin="0px auto 0px auto" sm-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="1fr 1fr"
				margin="50px 0px 0px 0px"
				md-grid-template-columns="1fr"
			>
				<Box
					lg-margin="0px 0px 40px 0px"
					display="grid"
					flex-direction="column"
					justify-content="flex-start"
					lg-width="100%"
					padding="36px 36px 36px 36px"
					margin="0px auto 0px auto"
					align-content="start"
					grid-gap="50px"
					md-padding="0 0 0 0"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2" color="--primary" sm-text-align="center">
						What we do
					</Text>
					<List
						margin="0px 0px 0px 0px"
						padding="0px 0px 0px 20px"
						list-style-type="none"
						as="ul"
						display="grid"
						grid-gap="16px"
						grid-template-columns="1fr 10fr"
						align-items="center"
					>
						<Icon
							category="fa"
							icon={FaBalanceScale}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								General Bookkeeping
								<br />
							</Strong>
							Maintain precise and up-to-date financial records.
						</Text>
						<Icon
							category="md"
							icon={MdAssignmentTurnedIn}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								VAT Returns
								<br />
							</Strong>
							Prepare and file your VAT returns accurately and on time.
						</Text>
						<Icon
							category="ti"
							icon={TiCalculator}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Year-End Accounts
							</Strong>
							<br />
							Compile year-end accounts, including balance sheets, profit and loss statements, and detailed explanations.
						</Text>
						<Icon
							category="md"
							icon={MdAccountBalance}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Annual Income Tax Returns
								<br />
							</Strong>
							Handle income tax returns for business owners.
						</Text>
						<Icon
							category="fa"
							icon={FaWpforms}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Tax Forms Completion
							</Strong>
							<br />
							Complete all business-related forms received from tax authorities.
						</Text>
						<Icon
							category="fa"
							icon={FaHandshake}
							size="64px"
							width="32px"
							color="--secondary"
						/>
						<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Advice and Support
							</Strong>
							<br />
							Provide expert advice and answer your financial questions.
						</Text>
					</List>
				</Box>
				<Box
					lg-margin="20px 0px 0px 0px"
					padding="36px 36px 48px 36px"
					background="--color-darkL2"
					md-padding="28px 20px 28px 20px"
					lg-width="100%"
					margin="0px auto 0px auto"
					id="form"
					box-shadow="--xl"
					border-radius="15px"
				>
					<Components.FreelancerForm reference="Freelancer EN">
						<Override slot="Fields1Wrapper" />
						<Override slot="InputArrival" />
						<Override slot="InputPhone" />
						<Override slot="HeadingText" />
						<Override slot="ArrivalLabel" />
						<Override slot="EmailLabel" />
						<Override slot="MessageLabel" />
						<Override slot="NameLabel" />
						<Override slot="PhoneLabel" />
						<Override slot="ServiceLabel" />
						<Override slot="InputEmail" />
						<Override slot="InputName" />
						<Override slot="SuccessMessage" />
						<Override slot="LoadingMessage" />
						<Override slot="ErrorMessage" />
						<Override slot="button">
							Submit
						</Override>
						<Override slot="box" />
						<Override slot="RequiredDescription">
							Items marked with an * are required fields.
						</Override>
					</Components.FreelancerForm>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="AccountCanAssistWith"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					ACCOUNTING REQUIREMENTS AND TAX OBLIGATIONS THAT WE CAN ASSIST SELF-EMPLOYED PROFESSIONALS
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaBalanceScale}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Opening balance sheet to support your visa application.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCalculator}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Maintain accurate financial records for your business.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaFileAlt}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								File and pay VAT returns.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="io"
							icon={IoMdPaper}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Prepare annual financial statements.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Filing and paying income tax returns in the Netherlands.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					display="block"
					border-radius="32px"
					max-height="600px"
					margin="0px auto 0px auto"
					srcSet="https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1614098805147-61657513491f?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="--color-light">
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					Are you a citizen of the United States or Japan looking to become self-employed in the Netherlands? Bos Balance can assist you in understanding the financial requirements for obtaining a residence permit. Here's the essential information you need:
				</Text>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					ELIGIBILITY CRITERIA TO BECOME SELF-EMPLOYED PROFESSIONALS
				</Text>
				<Text as="h2" font="normal 400 15px/1.5 --fontFamily-googleInter" color="--utOrange" margin="0px 0px 8px 0px">
					Applies only to Japanese and US citizens!
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="go"
							icon={GoLaw}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								You must meet general requirements along with specific criteria outlined in the Dutch-American Friendship Treaty (DAFT) or Dutch-Japanese Trade Treaty.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="go"
							icon={GoLaw}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Criteria include engaging in trade between the Netherlands and the US or Japan, leading business operations in the Netherlands, or representing a US or Japanese business in a key position.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
				text-align="center"
			>
				<Image
					src="https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
					display="block"
					border-radius="32px"
					margin="0px auto 0px auto"
					max-height="450px"
					srcSet="https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1599930113854-d6d7fd521f10?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					INVESTMENT REQUIREMENTS FOR SELF-EMPLOYED PROFESSIONALS
				</Text>
				<Text as="h2" font="normal 400 15px/1.5 --fontFamily-googleInter" color="--utOrange" margin="0px 0px 8px 0px">
					Applies only to Japanese and US citizens!
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								As part of the application process, you'll need to demonstrate a substantial investment in your business, with a minimum investment of €4,500 for most business forms.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1604594849809-dfedbc827105?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="--color-light"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					REGISTRATION STEPS FOR SELF-EMPLOYED PROFESSIONALS
				</Text>
				<Text as="h2" font="normal 400 15px/1.5 --fontFamily-googleInter" color="--utOrange" margin="0px 0px 8px 0px">
					Applies only to Japanese and US citizens!
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaWpforms}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Chamber of Commerce (KvK): Register your business with the Dutch Chamber of Commerce (KvK).
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaWpforms}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Immigration and Naturalisation Service (IND): Complete the application form and submit it within three months of arriving in the Netherlands. Upon approval, you'll receive a positive decision and can collect your residence permit.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaRegIdCard}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Local Municipality (BSN): Obtain a unique national identification number. The Burgerservicenummer (BSN) is crucial for various administrative processes.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="gi"
							icon={GiBank}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Bank Account Opening: Open a Dutch business bank account with a minimum deposit of € 4,500,-. Maintain this balance throughout your permit duration.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-28"
			background="#fafaf9"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="h2" font="--lead" color="--secondary" margin="0px 0px 8px 0px">
					APPLICATION PROCESS FOR SELF-EMPLOYED PROFESSIONALS
				</Text>
				<Text as="h2" font="normal 400 15px/1.5 --fontFamily-googleInter" color="--utOrange" margin="0px 0px 8px 0px">
					Applies only to Japanese and US citizens!
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaBalanceScale}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							Check Requirements: Ensure you meet all eligibility criteria and gather necessary documents.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCalculator}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							Submit Application: Complete the application form and submit it within three months of arriving in the Netherlands.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaFileAlt}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							Pay Application Fee: The application fee is €380.00.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="io"
							icon={IoMdPaper}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							Await Decision: The Immigration and Naturalization Service (IND) will process your application within 90 days, with the possibility of an extension in certain cases.
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="fa"
							icon={FaCoins}
							size="20px"
							color="--secondary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--primary" font="--base">
							Receive Decision: Upon approval, you'll receive a positive decision and can collect your residence permit.
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image
					src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					border-radius="32px"
					srcSet="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="#fafaf9">
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" color="--primary" font="--lead" text-align="center">
					At Bos Balance, we specialize in assisting individuals and businesses to comply with the complex local financial requirements. Let us help you achieve your entrepreneurial goals in the Netherlands. Contact us today to get started!
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				text-align="center"
				align-items="center"
				display="grid"
				justify-items="center"
			>
				<Button
					href="#form"
					background="--color-utOrange"
					padding="8px 24px 8px 30p"
					color="--light"
					font="--lead"
					hover-transform="scale(1.1)"
					active-background="--color-secondary"
					transition="all 0.5s ease 0s"
					focus-box-shadow="unset"
					focus-background="--color-secondary"
					border-radius="5px"
					box-shadow="--l"
					type="link"
					text-decoration-line="initial"
				>
					CONTACT US
				</Button>
			</Box>
		</Section>
		<Section background="#fafaf9">
			<Button
				background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/Flag_of_Japan.svg?v=2024-06-08T13:56:50.908Z) 0% 0%/contain no-repeat"
				font="--base"
				top="596px"
				bottom="4999px"
				color="--secondary"
				width="max-content"
				padding="8px 24px 8px 73px"
				box-shadow="--xxl"
				border-radius="15px"
				hover-box-shadow="none"
				transition="all 0.5s ease 0s"
				opacity="0.8"
				hover-opacity="1"
				type="link"
				text-decoration-line="initial"
				href="/freelancers-jp"
			>
				日本語サイトはこちら
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="Cookie Consent" />
			<Override slot="text4">
				© 2024 BOS BALANCE BV. All rights reserved.
			</Override>
		</Components.Footer>
		<Button
			type="link"
			text-decoration-line="initial"
			position="fixed"
			right={0}
			bottom={0}
			margin="0px 5px 5px 0px"
			background="url(https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/WhatsAppButtonGreenMedium.svg?v=2024-06-08T12:53:12.802Z) 0% 0%/contain no-repeat"
			width="192px"
			height="42px"
			box-shadow="--xl"
			href="https://wa.me/31616555767?text=Hi%20Bos%20Balance%2C%20I%27m%20interested%20in%20your%20accounting%20services!"
			opacity="0.9"
			transition="all 0.5s ease 0s"
			hover-transform="rotate(3deg)"
		/>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});